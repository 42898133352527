.btn-color{
  background-color: black !important;
}

.sidebar {
  background: transparent ;
}

.App {
  text-align: center;
}

td.test {
  width: 600px;
  
  display: block;
}
td.test1 {
  width: 276px;
  text-align: center;
}

.panel-header{
  height:125px !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.active{
color:red;
}
.dropdown {
  position: relative;
  display: inline-block;
  background:  none !important;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;

  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 1;
  right: -11px;
  border-bottom:1px solid #eee;
}
.dropdown-content p{
  color:#000 !important;
  font-size:13px !important;
  text-transform:uppercase !important;
}
.dropdown:hover .dropdown-content {
  display: block;
}
.dropdown-content hr{
color: #f96332;
    height: 0.25px;
    margin:0px !important;
}

/* #cell-6-undefined div {
  padding-left: 51px !important;
  margin-left: 51px !important;
   overflow: visible !important;
} */

/* #cell-5-undefined div {
  overflow: visible !important;
}
#cell-5-undefined {
  overflow: visible !important;
}

#cell-4-undefined  {
  overflow: visible !important;
}
#cell-6-undefined  {
  overflow: visible !important;
} */



/*vsh*/
.tbl-tradehostory thead tr th {
    color: rgba(0,0,0,0.87);
    font-size: 12px !important;
    font-weight: 500 !important;
}

.tbl-tradehostory tbody tr td {
    font-size: 13px;
    font-weight: 400;
    color: rgba(0,0,0,0.87);
    background-color: #FFFFFF;
    min-height: 48px;
}
.trade-space {
    margin: 20px 0;
}
.tradehostory-w {
    width: 0;
}
table.table.tbl-tradehostory tbody {
    border-top: 1px solid #dee2e6 !important;
}
table.table.tbl-tradehostory tfoot {
    border-top: 1px solid #dee2e6 !important;
}
.input-group-append .input-group-text {
    padding: 10px 15px !important;
        border-radius: 0 20px 20px 0 !important;
}

.broker_switch input{
  width: 51px !important;
  height: 31px !important;
}

.r-btn label {
  margin-right: 32px;
  display: inline-flex;
  align-items: center;
}
.r-btn input.demo_status.lictype {
  margin-right: 11px;
}
.btn-new
{
  padding: 4px 13px !important;
  margin-right: 10px !important;
}
.d-flex
{
  display: flex;
  align-items: baseline;
}
.r-btn
{
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}
.btn-new-block {
  padding: 4px 14px !important;
}

a:active {
  /*background-color: #FFFFFF !important;*/
  box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%)  !important;
}

/* client profile */
.profile-user-img {
  margin: 0 auto;
  width: 100px;
  padding: 3px;
  border: 3px solid #d2d6de;
}
.box-body.box-profile {
  background: #fff;
  text-align: center;
}
.profile-username {
  font-size: 21px;
  margin-top: 5px;
}
 
.content-header {
  position: relative;
  padding: 15px 15px 15px 15px;
}
.content-header>h1 {
  margin: 0;
  font-size: 24px;
} 

.content-header>h1>small {
  font-size: 15px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
}
.profile-user-img {
  margin: 17px auto;
  width: 100px;
  padding: 3px;
  border-radius: 50%;
  border: 3px solid #d2d6de;
}
.list-group-item 
{
  text-align: left;
}
.content-header>h1>small {
  font-size: 14px;
  display: inline-block;
  padding-left: 4px;
  font-weight: 300;
  text-transform: capitalize;
}
.nav-tabs-custom {
  background:#fff;
}
.password{
  padding:20px;
}
.submit{
  background-color: #3c8dbc;
  /* border-color: #367fa9; */
  color: #fff;
  padding: 10px 40px;
  border: none;
  border-radius: 5px;
  margin: 0px 10px 30px;
}
.Reset{
background-color: #f4f4f4;
color: #444;
border:none;
padding: 10px 40px;
border-radius: 5px;
}
.profile_description{
  padding:10px 20px;
}
.profile_username{
  font-size: 21px;
  margin-top: 5px;
  color:#000;
}
.col-md-3.export-btn button {
    color: #fff;
    background: #f96332;
    border-width: 2px;
    font-weight: 400;
    font-size: 0.8571em;
    line-height: 1.35em;
    border: none;
    margin: 10px 1px;
    border-radius: 0.1875rem;
    padding: 11px 22px;
    cursor: pointer;
    background-color: #f96332;
    color: #FFFFFF;
}

.XlRpR {
  position: relative;
  width: 94% !important;
  display: table;
}


.text-right {
  text-align: right;
}


/* a{
  text-decoration: none;
  color: black;
}

a:visited{
  color: black;
} */

.box::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: #F5F5F5;
  border-radius: 5px
}

.box::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
  border-radius: 5px
}

.box::-webkit-scrollbar-thumb
{
	background-color: #204166;
	border: 2px solid #204166;
  border-radius: 5px
}




.icons{
  display: inline;
  float: right
}

.notification{
  padding-top: 22px;
  position: relative;
  display: inline-block;
}

.number {
  height: 20px;
  width: 20px;
  background-color: #d63031;
  border-radius: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 10px;
  left: 49px;
  padding: 3px;
 
}
.number span {
  position: absolute;
  top: 0px;
  left: 6px;
  font-size: 14px;
}
.number:empty {
   display: none;
}

.notBtn{
  transition: 0.5s;
  cursor: pointer
}
.notification .fas {
  font-size: 20px;
  padding-bottom: 10px;
  color: white;
  margin-right: 25px;
  margin-left: 35px;
}
.box{
  width: 400px;
  height: 0px;
  border-radius: 10px;
  transition: 0.5s;
  position: absolute;
  overflow-y: scroll;
  padding: 0px;
  left: -300px;
  margin-top: 5px;
  background-color: #F4F4F4;
  -webkit-box-shadow:  0 1px 15px 1px rgb(39 39 39 / 10%);
  -moz-box-shadow:  0 1px 15px 1px rgb(39 39 39 / 10%);
  box-shadow: 0 1px 15px 1px rgb(39 39 39 / 10%);
  cursor: context-menu;
}

.fas:hover {
  color: #d63031;
}

.notBtn:hover > .box{
  height: 60vh
}

ul.txt li {
  padding: 10px;
  border-bottom: 2px solid #f66232;
 
}

.display{
  position: relative;
}

.cont{
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #F4F4F4;
}

.cont:empty{
  display: none;
}
.sec{
  padding: 25px ;
  background-color: #fff;
  transition: 0.5s;
}

.profCont{
  padding-left: 15px;
}
.txt {
  vertical-align: top;
  font-size: 16px;
  padding: 5px 20px 0px 20px;
  color: #1c3a5e;
}
.box h5 {
  color: #000;
  text-align: center;
  margin: 10px 0px;
}
.profile.dropdown button {
  background: none !important;
  margin: 0px;
}
.profile.dropdown button:hover {
  background: transparent;
  box-shadow: none !important;
}
.profile.dropdown button:focus {
  background-color: transparent;
  color: #FFFFFF;
  box-shadow: none;
  /* border-color: #fa7a50; */
}
li.user-header img {
  width: 100px;
  text-align: center;
  margin: 0px auto;
}

.profile ul.dropdown-menu {
  width: 205px;
  left: 50px;
}
li.user-footer {
  padding: 0px 10px;
}
li.user-header {
  text-align: center;
}


.filter-text{
  /* border: none !important; */
  padding: 2px 20px ;
  border-radius: 5px ;
  /* background-color: rgb(248, 212, 212); */
}
.filter-text:focus{
  outline: none !important;
  border-bottom: 1px solid rgb(170, 166, 166) !important;
}



.data-table-extensions-filter{
  margin: 24px 13px !important;
}

/*  Client > Dashboard > Column bold */

.tbl-tradehostory thead tr th {
  margin: 15px 0px !important;
  color: #fff !important;
  font-size: 1em !important;
  font-weight: 700 !important;
}


/*    Header Profile section   */

#dropdownMenu{
  transform: translate3d(-90px, -20px, 0) !important;
}


div.dropdown-menu {
  visibility: hidden !important;
}


::-webkit-scrollbar {
  width: 5px!important;
  border-radius: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: rgb(238, 93, 93);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(252, 3, 3);
}

#dropdownMenuu{
  transform: translate3d(-322px, -29px, 0) !important;
  width: 340px !important;
  height: 400px !important;
  border-radius: 10px !important;
  background-color: #fff;
  overflow-y: scroll !important;
 overflow-x: hidden; 
}

.box::-webkit-scrollbar{
  width: 3px;
}

.dropdown-menu:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  vertical-align: middle;
  content: "";
  top: -5px;
  left: 168px !important;
  right: 22px;
  color: #FFFFFF;
  border-bottom: 0.4em solid;
  border-right: 0.4em solid transparent;
  border-left: 0.4em solid transparent;
}

.bedge{
  position: absolute;
  top: 10px;
  left: 39px;
  font-size: 14px;
  background: red;
  font-weight: bold !important;
  width: 22px;
  height: 22px;
  text-align: center;
  outline: none;
  border-radius: 50%;
  border: none;
}

/* .drop-ul{
  width: 330px !important;
  height: 500px !important;
  overflow-y: scroll !important;
  overflow-x: auto !important;
} */

.bsgpsK div:first-child {
  overflow: visible !important;
}
.offcanvas-start {
  width: 300px !important;
}
.sidebar, .bootstrap-navbar {
  position: fixed;
  display: block;
  top: 0;
  height: 100vh;
  width: 260px;
  /* right: auto; */
  left: 0;
  z-index: 1032;
  visibility: visible;
  overflow-y: visible;
  padding: 0;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
  /* transform: translate3d(0px, 0, 0) !important; */
}


/* Header trash Hover */

.hover:hover{
color: red ;
transition: all .3s linear;
cursor: pointer;
}

.edit_hover:hover{
  color: rgb(59, 182, 59);
transition: all .3s linear;
cursor: pointer;

}

.edit_hover{
  color:  #111 ;
}

.backHover:hover{
  color: rgb(247, 0, 0);
  transition: all .3s linear;
  cursor: pointer;
}

.addhover:hover{
  color: rgb(49, 9, 9);
  transition: all .3s linear;
  cursor: pointer;
}

.addhover{
  color: rgb(49, 9, 9);
  transition: all .3s linear;
  cursor: pointer;
}

.h{
  position: absolute;
  left: 50%;
  top: 50%;
  transform: (-50% ,50%);
  transform: translate(-50%, -50%);

}



/* .rotate-center:hover{
  color: rgb(170, 135, 157);
  transition: all .3s linear;
  cursor: pointer;

} */
.rotate-center{
	-webkit-animation: rotate-center 0.6s ease-in-out both;
	        animation: rotate-center 0.6s ease-in-out both;
}
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  50% {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
}



.hoverRefresh:hover{
/* transform: rotate(360); */
color: rgb(170, 135, 157);
transition: all .3s linear;
cursor: pointer;
}

/*======================
    404 page
=======================*/


.page_404{ padding:40px 0; background:#fff; font-family: 'Arvo', serif;
}

.page_404  img{ width:100%;}

.four_zero_four_bg{
 
 background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
    width: 1254px;
    margin-top: 70px;

 }

 .contant_box_404 {
  margin-top: 10px !important;
}
 
 
 .four_zero_four_bg h1{
 font-size:80px;
 }
 
  .four_zero_four_bg h3{
			 font-size:80px;
			 }
			 
			 .link_404{			 
	color: #fff!important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;}
	.contant_box_404{ margin-top:-50px;}

  .tablecolor{
    background-color: #000 !important;
    color: #fff !important;
  }

  .tableheight{
    height: 60vh !important;
    overflow-y: scroll !important;
  }

   /* Translator Css --------------------------------------- */

   /* .goog-te-gadget {
    color: transparent !important;
}

.goog-logo-link {
    display: none !important;
}

.goog-te-banner-frame.skiptranslate {
    display: none !important;
}

body {
    top: 0px !important;
} */

/* ----------------------- Responsive Design  */

@media all and (max-width: 425px) {

#totalLicence{
  text-align: center;
  flex-direction: column;
}


 }
 
/* @media all and (min-width: 768px) and (max-width: 1024px) { }
 
@media all and (min-width: 480px) and (max-width: 768px) { }
 
@media all and (max-width: 480px) { } */